import './App.css';
import './Navigation';
import Navigation from './Navigation';
import { useSelector } from "react-redux";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import "swagger-ui-react/swagger-ui.css"
import AuthProvider from './common/AuthProvider';
import MiHinSwaggerUI from './common/MiHinSwaggerUI';
import { Provider } from "react-redux";
import store from "./store";

function App() {

  return (
    <Provider store={store}> 
    <AuthProvider >
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={
            <div className="page">
              <div className="sidebar">
                <Navigation></Navigation>
              </div>
              <header>
              </header>
              <div className="main">
                <div className="content px-4">
                  <span className="active-care-header">Sam Henderson, M, 56 yrs </span><span className="active-care-subheader"> | Spectrum Health
                    | Dr. Henry Smitherson, MD (PCP)</span>
                  <div className="card card-patient-events">
                    <div className="card-header card-patient-events-header">
                      KNOWN PATIENT EVENTS (20)
                      <span className="float-right badge badge-pill badge-secondary card-patient-events-badge">68 Patient
                        Attributes</span>
                      <span className="float-right badge badge-pill badge-secondary card-patient-events-badge">8 Potantial Gaps in
                        Care</span>
                      <span className="float-right badge badge-pill badge-secondary card-patient-events-badge">4 Provider Types</span>
                      <span className="float-right badge badge-pill badge-secondary card-patient-events-badge">3 Significant Events</span>
                    </div>
                    <div className="card-body">
                      <img src="dummy_graph.png" />
                    </div>
                  </div>
                  <br />
                  <div className="card">
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th scope="col">DATE</th>
                          <th scope="col">EVENT</th>
                          <th scope="col">NOTES</th>
                          <th scope="col">TESTS</th>
                          <th scope="col">OUTCOME / DIAGNOSIS</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>8/01/21</td>
                          <td>ER Visit Butterworth Hospital, Grand Rapids</td>
                          <td>Presenting with neurological symptoms, involuntary muscle movements, confusion.</td>
                          <td>MRI, CBC Lab</td>
                          <td>Hypercapnia, paraganglioma</td>
                        </tr>
                        <tr>
                          <td>8/02/21</td>
                          <td>Surgical Consult Spectrum Ionia 245</td>
                          <td>Physical, medication review. Referred to Head & Neck Specialist</td>
                          <td>N/A</td>
                          <td>Continue care for paraganglioma, New diagnosis: Sleep Apnea</td>
                        </tr>
                        <tr>
                          <td>Gap in Care</td>
                          <td><em>Patient Missed Appointment with PCP, M. Romero</em></td>
                          <td>No call, no show. Message was left on patient's voicemail.</td>
                          <td>N/A</td>
                          <td>N/A</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>}

          />
          <Route exact path="/swagger" element={<MiHinSwaggerUI></MiHinSwaggerUI>}
          />

        </Routes>
      </BrowserRouter>
    </AuthProvider>
    </Provider>

  ) 
}

export default App;
