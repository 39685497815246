import OAuth2 from "react-simple-oauth2-login";
import { useDispatch, useSelector } from "react-redux";
import tokenSlice from "../store/tokenSlice";
import apiSpecSlice from "../store/apiSpecSlice";
import React, { useEffect } from 'react';


const AuthProvider = ({ children }) => {

  const authUrl = process.env.REACT_APP_AUTH_URL
  const clientId = process.env.REACT_APP_CLIENT_ID
  const callbackUrl = process.env.REACT_APP_CALLBACK_URL
  const apiSpecUrl = process.env.REACT_APP_OPENAPI_SPEC

  const button = React.createRef()

  useEffect(()=>{button.current.onBtnClick()},[]);

  const dispatch = useDispatch();

  const onSuccess = (response) => fetchApi(response);
   
  const onFailure = (response) => console.error(response);
  const fetchApi = async (response) => {

    console.log(response.access_token)
    dispatch(tokenSlice.actions.setToken(response.access_token))
    const headers = new Headers();
    headers.set('Authorization', "Bearer " + response.access_token);
    const res = await fetch(apiSpecUrl,
      {
        method: 'GET',
        headers
      })

    const data = await res.json()
    data.host = (new URL(apiSpecUrl)).hostname
    data.schemes = ["https"]

    console.log(data)
    dispatch(apiSpecSlice.actions.setApiSpec(data))

  }
  const access_token = useSelector((state) => state.token);

  return !access_token ? (
    <OAuth2
        ref={button}
        authorizationUrl={authUrl}
        responseType="token"
        clientId={clientId}
        redirectUri={callbackUrl}
        onSuccess={onSuccess}
        onFailure={onFailure}
    />
  ) : (children);
};

export default AuthProvider;
