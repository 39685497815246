import './Navigation.css';
import React, { Component } from 'react';

export default class Navigation extends Component {
    render() {
        return (
            <div>
                <div className="navbar-l1-label">
                    <span>‹ BACK TO SEARCH</span>
                </div>
                <hr />

                <div>
                    <span className="navbar-l1-label">FILTER PATIENT DATA</span>
                    <br /><br />
                    <ul className="nav flex-column navbar-filter-panel">
                        <label for="select-view">View</label>
                        <select id="select-view">
                            <option value="All" selected>Display All Events</option>
                        </select>
                        <label for="select-timespan">Time Span</label>
                        <select id="select-timespan">
                            <option value="Current" selected>Current</option>
                        </select>
                        <label for="select-attribute-type">Attribute Type</label>
                        <select id="select-attribute-type">
                            <option value="All" selected>View All Types</option>
                        </select>
                        <label for="select-provider">Provider</label>
                        <select id="select-provider">
                            <option value="All" selected>All Providers</option>
                        </select>
                        <label for="select-care-system">Care System</label>
                        <select id="select-care-system">
                            <option value="Spectrum Health" selected>Spectrum Health</option>
                        </select>
                    </ul>
                </div>
            </div>
        )
    }
}