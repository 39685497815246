import { useDispatch, useSelector } from "react-redux";
import SwaggerUI from "swagger-ui-react"


const MiHinSwaggerUI = ({ children }) => {
    const apispec = useSelector((state) => state.apispec);
    const access_token = useSelector((state) => state.token);
    return apispec ? <SwaggerUI spec={apispec} persistAuthorization="true" operationsSorter="alpha" tagsSorter="alpha"  requestInterceptor={
        async (req) => {

          try {
            //make request verify token
            // null token 

            if (!req.loadSpec) {
              req.headers.Authorization = "Bearer " + access_token;
            }
            return req

          } catch (err) {
            console.log(err)
          }

        }

      }/> : <SwaggerUI></SwaggerUI>
}

export default MiHinSwaggerUI;
